import 'core-js/stable';
// import objectFitImages from 'object-fit-images';

window.usingPolyfill = true;
console.log('Polyfills ready');

// window.objectFitImages = objectFitImages;

// document.addEventListener('DOMContentLoaded', function() {
    // objectFitImages('img');
// });